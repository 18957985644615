import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import RatingComponent from './MyRatingComponent';
import { useGuid } from './GuidContext'; // For getting the user's ID

const getTodaysDateInCentralTime = () => {
    const options = { timeZone: 'America/Chicago', year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date().toLocaleDateString('en-CA', options); // 'en-CA' = YYYY-MM-DD by default
};

const getYesterdaysDateInCentralTime = () => {
    const options = { timeZone: 'America/Chicago', year: 'numeric', month: '2-digit', day: '2-digit' };
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toLocaleDateString('en-CA', options);
};

const DrawingsList = ({ setShowSubject }) => {
    const [drawings, setDrawings] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    // Remove old page-based state
    // const [page, setPage] = useState(1);

    // Add lastEvaluatedKey to manage DynamoDB pagination
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);

    const [userRatings, setUserRatings] = useState(() => JSON.parse(localStorage.getItem('userRatings')) || {});
    const [currentDate] = useState(getTodaysDateInCentralTime());
    const [viewingDate, setViewingDate] = useState(getTodaysDateInCentralTime());
    const [subject, setSubject] = useState('');

    const firstLoad = useRef(true);
    const itemsPerPage = 3; // how many items per request
    const { guid } = useGuid();

    const fetchMoreDrawings = async () => {

        const localDate = viewingDate;   // <-- Add this line
        try {
            // Build the URL based on date, items_per_page, and lastEvaluatedKey
            let url = `https://ibt18xjvyj.execute-api.us-east-1.amazonaws.com/getVotedDoodles?date=${viewingDate}&items_per_page=${itemsPerPage}`;

            // If we have a lastEvaluatedKey from the previous request, include it
            if (lastEvaluatedKey) {
                // Encode it so it can be safely passed as a query string
                const lekStr = encodeURIComponent(JSON.stringify(lastEvaluatedKey));
                url += `&last_evaluated_key=${lekStr}`;
            }

            const response = await axios.get(url);

            // After the response, verify we're still on the same date
            if (localDate !== viewingDate) {
                return;                      // <-- Add this check
            }
            // Our new Lambda response should look like: { items: [...], lastEvaluatedKey: {...} or null }
            const { items, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

            // If this is the first batch, and there's at least one drawing, store its subject
            // (Your code currently picks the subject from the first item returned)
            if (!lastEvaluatedKey && items.length > 0) {
                setSubject(items[0].drawingSubject || '');
            }

            // Append new items to the existing list
            setDrawings((prev) => [...prev, ...items]);

            // Update the lastEvaluatedKey
            setLastEvaluatedKey(newLastEvaluatedKey || null);

            // If DynamoDB returned no new key, we’re out of data
            if (!newLastEvaluatedKey) {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Failed to fetch drawings:', error);
        }
    };

    // Whenever the user changes the date (Today/Yesterday), reset everything
    useEffect(() => {
        setShowSubject(false);
        setDrawings([]);
        setHasMore(true);
        setLastEvaluatedKey(null);    // reset our pagination cursor
        fetchMoreDrawings();
        firstLoad.current = false;
    }, [viewingDate]);

    // On mount, check if new day => reset local ratings
    useEffect(() => {
        const lastVisited = localStorage.getItem('lastVisited');
        if (lastVisited !== currentDate) {
            setUserRatings({});
            localStorage.setItem('userRatings', JSON.stringify({}));
            localStorage.setItem('lastVisited', currentDate);
        } else {
            const savedRatings = localStorage.getItem('userRatings');
            if (savedRatings) {
                setUserRatings(JSON.parse(savedRatings));
            }
        }
    }, [currentDate]);

    // Voting logic (unchanged)
    const voteForDrawing = async (userId, rating) => {
        try {
            await fetch('https://ibt18xjvyj.execute-api.us-east-1.amazonaws.com/SubmitVote', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userId: userId,
                    rating: rating,
                    drawingDate: getTodaysDateInCentralTime()
                })
            });
        } catch (error) {
            console.error('Error saving vote:', error);
        }
    };

    const setToday = () => {
        setViewingDate(getTodaysDateInCentralTime());
    };

    const setYesterday = () => {
        setViewingDate(getYesterdaysDateInCentralTime());
    };

    const today = getTodaysDateInCentralTime();
    const yesterday = getYesterdaysDateInCentralTime();

    const handleRatingSelected = (userId, newRating) => {
        console.log(`Rating selected for guid ${userId}: ${newRating}`);

        // Update local state for user ratings
        const newUserRatings = { ...userRatings, [userId]: newRating };
        setUserRatings(newUserRatings);

        // Save the new rating to localStorage
        localStorage.setItem('userRatings', JSON.stringify(newUserRatings));

        // Submit the new rating to the server
        voteForDrawing(userId, newRating);
    };

    return (
        <>
            <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>
                {viewingDate === today ? (
                    <span>Today</span>
                ) : (
                    <a
                        href="#"
                        onClick={setToday}
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    >
                        Today
                    </a>
                )}
                {' | '}
                {viewingDate === yesterday ? (
                    <span>Yesterday</span>
                ) : (
                    <a
                        href="#"
                        onClick={setYesterday}
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    >
                        Yesterday
                    </a>
                )}
            </div>

            {/* Show the subject if we have one */}
            {subject && (
                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    {subject}
                </div>
            )}

            <InfiniteScroll
                key={viewingDate}
                dataLength={drawings.length}
                next={fetchMoreDrawings}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>You have seen all the doodles.</b>
                    </p>
                }
            >
                {drawings.map((drawing, index) => (
                    <div
                        key={index}
                        style={{
                            margin: '0px auto',
                            borderTop: '1px solid black',
                            borderLeft: '1px solid black',
                            borderRight: '1px solid black',
                            borderBottom: 'none',
                            padding: '5px',
                            maxWidth: '600px'
                        }}
                    >
                        {drawing.signature && (
                            <div style={{ textAlign: 'center', marginBottom: '5px' }}>
                                {drawing.signature}
                            </div>
                        )}
                        <div style={{ position: 'relative', margin: '5px' }}>
                            <img
                                src={drawing.imageUrl}
                                alt=""
                                style={{
                                    display: 'block',
                                    width: '100%',
                                    height: 'auto'
                                }}
                            />
                            <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                <RatingComponent
                                    rating={drawing.rating}
                                    readOnly
                                    small={true}
                                    averageRating={drawing.rating}
                                    numRaters={drawing.ballotCount}
                                />
                            </div>
                        </div>
                        {viewingDate === getTodaysDateInCentralTime() && guid !== drawing.userId && (
                            <RatingComponent
                                rating={userRatings[drawing.userId] || 0}
                                onRatingSelected={(newRating) => handleRatingSelected(drawing.userId, newRating)}
                                readOnly={!!userRatings[drawing.userId]}
                            />
                        )}
                    </div>
                ))}
            </InfiniteScroll>
        </>
    );
};

export default DrawingsList;
