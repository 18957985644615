import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGuid } from './GuidContext';
import RatingComponent from './MyRatingComponent'; // Make sure this path is correct

function MyDoodles({ setShowSubject }) {
    const { guid } = useGuid();
    const [drawings, setDrawings] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [lastKey, setLastKey] = useState(null);

    useEffect(() => {
        // Reset drawings if GUID changes
        setDrawings([]);
        setPage(1);
        setHasMore(true);
        fetchDrawings(page);
        setShowSubject(false);
    }, [guid]);

    const fetchDrawings = async (currentPage) => {
        if (!guid) {
            console.warn('GUID is not available yet.');
            return;
        }

        try {
            const queryParams = new URLSearchParams({ userId: guid, page });
            if (lastKey) {
                queryParams.append('LastEvaluatedKey', JSON.stringify(lastKey));
            }

            const response = await fetch(`https://ibt18xjvyj.execute-api.us-east-1.amazonaws.com/GetDoodles?${queryParams.toString()}`);
            const data = await response.json();

            if (data.drawings && Array.isArray(data.drawings)) {
                const newDrawings = data.drawings.map(drawing => ({
                    imageUrl: drawing.imageUrl,
                    rating: drawing.rating,
                    ballotCount: drawing.ballotCount,
                    dailySubject: drawing.drawingSubject
                }));
                setDrawings(drawings => [...drawings, ...newDrawings]);
                setPage(prevPage => prevPage + 1);
                setLastKey(data.LastEvaluatedKey);
                setHasMore(!!data.LastEvaluatedKey);
            } else {
                console.warn("Unexpected data format received:", data);
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching drawings:', error);
            setHasMore(false);
        }
    };

    return (
        <div style={{ maxWidth: '1000px', margin: '0 auto', padding: '10px' }}>
            <InfiniteScroll
                dataLength={drawings.length}
                next={() => fetchDrawings(page)}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage={<p style={{ textAlign: 'center' }}>You have seen it all</p>}
                // Increase the min width to make doodles larger on bigger screens.
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                    gap: '20px',
                    justifyContent: 'center'
                }}
            >
                {drawings.map((drawing, index) => (
                    <div key={index} style={{
                        borderTop: '1px solid black',
                        borderLeft: '1px solid black',
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                        padding: '5px',
                        backgroundColor: '#fff'
                    }}>
                        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                            {drawing.dailySubject}
                        </div>
                        <img
                            src={drawing.imageUrl}
                            alt="Drawing"
                            style={{
                                width: '100%',
                                height: 'auto',
                                display: 'block',
                                margin: '0 auto'
                            }}
                        />
                        <RatingComponent
                            rating={drawing.rating}
                            readOnly
                            numRaters={drawing.ballotCount}
                            averageRating={drawing.rating}
                        />
                    </div>
                ))}
            </InfiniteScroll>
        </div>
    );
}

export default MyDoodles;
