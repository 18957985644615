import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useGuid } from './GuidContext';
import { ColorPalette } from './ColorPalette';
import RatingComponent from './MyRatingComponent';
import ConfirmDialog from './ConfirmDialog';

const MAX_LAYERS = 5;

const DrawingCanvas = ({
    color,
    setColor,
    mySaveDrawingRef,
    isPublished,
    setIsPublished,
    myShareDrawingRef,
    setShowSubject
}) => {
    const [layers, setLayers] = useState([]);         // Array of { canvasRef, context } objects
    const [activeLayerIndex, setActiveLayerIndex] = useState(0);
    const mainCanvasRef = useRef(null);
    const mainContext = useRef(null);
    const workCanvasRef = useRef(null);
    const workContext = useRef(null);
    const beginDrawLayerCanvasRef = useRef(null);
    const beginDrawLayerContext = useRef(null);
    const offScreenCanvasRef = useRef(null);
    const offScreenContext = useRef(null);

    const { guid } = useGuid();

    const [isDrawing, setIsDrawing] = useState(false);
    const [touchPoints, setTouchPoints] = useState([]);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [brushSize, setBrushSize] = useState(2);
    const [alpha, setAlpha] = useState(1.0);
    const [eraseMode, setEraseMode] = useState(false);
    const [lastPoint, setLastPoint] = useState(null);
    const [dailySubject, setDailySubject] = useState('');
    const [isCanvasModified, setIsCanvasModified] = useState(false);
    const [lineMode, setLineMode] = useState('squiggle');
    const [specialBrushMode, setSpecialBrushMode] = useState(false);
    const [lineStart, setLineStart] = useState({ x: 0, y: 0 });
    const [transformMode, setTransformMode] = useState(false);
    const touchHoldTimerRef = useRef(null);
    const [history, setHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);
    const [sampleMode, setSampleMode] = useState(false);
    const [hasMoved, setHasMoved] = useState(false);
    const [recentResize, setRecentResize] = useState(false);

    const [scale, setScale] = useState(1);
    const [showSplash, setShowSplash] = useState(false);
    const [showSignaturePrompt, setShowSignaturePrompt] = useState(false);
    const [signature, setSignature] = useState('');
    const [localSignature, setLocalSignature] = useState('');

    const [showConfirm, setShowConfirm] = useState(false);

    // 1) We need a boolean to track if it’s a same-day load
    const [shouldLoad, setShouldLoad] = useState(false);

    const [showLayerMenuIndex, setShowLayerMenuIndex] = useState(null);

    // Right after your other state variables:
    const [layerButtonImages, setLayerButtonImages] = useState([]);


    const [isTransforming, setIsTransforming] = useState(false);
    const [transformStartData, setTransformStartData] = useState({
        // Original two-finger positions for rotation/scale
        startTouches: [],
        // The midpoint around which we rotate/scale
        anchorX: 0,
        anchorY: 0,
        // Distances/angles at the beginning
        startDistance: 0,
        startAngle: 0,
        // For single-finger or multi-finger translation
        startOffsetX: 0,
        startOffsetY: 0,
    });
    // We track the “current” transform being previewed
    const [transformPreview, setTransformPreview] = useState({
        translateX: 0,
        translateY: 0,
        rotation: 0,
        scale: 1,
    });


    // 1) Adjust refreshLayerButtonImages to accept a layers array
    const refreshLayerButtonImages = useCallback((layerArray) => {
        // Create an offscreen canvas to avoid flickering
        const offscreenCanvas = document.createElement('canvas');
        // Match or downscale your final thumb size — here 35x35 just as an example:
        offscreenCanvas.width = 35;
        offscreenCanvas.height = 35;
        const offscreenCtx = offscreenCanvas.getContext('2d');

        const updated = layerArray.map((layer) => {
            // Clear the offscreen before drawing each layer
            offscreenCtx.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);

            // Draw the layer’s canvas into the offscreen (scaled down)
            offscreenCtx.drawImage(
                layer.context.canvas,
                0,
                0,
                offscreenCanvas.width,
                offscreenCanvas.height
            );

            // Convert the offscreen buffer to a DataURL for the layer button
            return offscreenCanvas.toDataURL();
        });

        setLayerButtonImages(updated);
    }, []);

    const moveLayerUp = (layerIndex) => {
        if (layerIndex >= layers.length - 1) return;  // already top
        setLayers((prev) => {
            const newLayers = [...prev];
            [newLayers[layerIndex], newLayers[layerIndex + 1]] = [
                newLayers[layerIndex + 1],
                newLayers[layerIndex]
            ];
            refreshLayerButtonImages(newLayers);
            return newLayers;
        });
        // If moving the active layer up, shift its index accordingly
        if (activeLayerIndex === layerIndex) {
            setActiveLayerIndex(layerIndex + 1);
        } else if (activeLayerIndex === layerIndex + 1) {
            setActiveLayerIndex(layerIndex);
        }
        setShowLayerMenuIndex(null);

    };

    const moveLayerDown = (layerIndex) => {
        if (layerIndex <= 0) return; // already bottom
        setLayers((prev) => {
            const newLayers = [...prev];
            [newLayers[layerIndex], newLayers[layerIndex - 1]] = [
                newLayers[layerIndex - 1],
                newLayers[layerIndex]
            ];
            refreshLayerButtonImages(newLayers);
            return newLayers;
        });
        // Adjust active layer index if necessary
        if (activeLayerIndex === layerIndex) {
            setActiveLayerIndex(layerIndex - 1);
        } else if (activeLayerIndex === layerIndex - 1) {
            setActiveLayerIndex(layerIndex);
        }
        setShowLayerMenuIndex(null);
    };

    const mergeLayerUp = (layerIndex) => {
        if (layerIndex >= layers.length - 1) return; // no layer above
        setLayers((prev) => {
            const newLayers = [...prev];
            const lowerCtx = newLayers[layerIndex].context;
            const upperCtx = newLayers[layerIndex + 1].context;

            // The layer above is drawn over the merging layer
            const offscreen = document.createElement('canvas');
            offscreen.width = lowerCtx.canvas.width;
            offscreen.height = lowerCtx.canvas.height;
            const offscreenCtx = offscreen.getContext('2d');
            offscreenCtx.drawImage(lowerCtx.canvas, 0, 0);  // merging layer first
            offscreenCtx.drawImage(upperCtx.canvas, 0, 0);  // then layer above

            // Copy merged result back into the upper layer
            upperCtx.clearRect(0, 0, upperCtx.canvas.width, upperCtx.canvas.height);
            upperCtx.drawImage(offscreen, 0, 0);

            // Remove the lower layer from the array
            newLayers.splice(layerIndex, 1);
            refreshLayerButtonImages(newLayers);
            return newLayers;
        });

        // Adjust active layer index if we removed the active layer
        if (activeLayerIndex === layerIndex) {
            setActiveLayerIndex(layerIndex); // The same numeric index now refers to what used to be layerIndex+1
        } else if (activeLayerIndex > layerIndex) {
            setActiveLayerIndex(activeLayerIndex - 1);
        }
        setShowLayerMenuIndex(null);
    };

    const mergeLayerDown = (layerIndex) => {
        if (layerIndex <= 0) return; // no layer below
        setLayers((prev) => {
            const newLayers = [...prev];
            const upperCtx = newLayers[layerIndex].context;
            const lowerCtx = newLayers[layerIndex - 1].context;

            // "The merged layer will be written over the layer below it"
            const offscreen = document.createElement('canvas');
            offscreen.width = upperCtx.canvas.width;
            offscreen.height = upperCtx.canvas.height;
            const offscreenCtx = offscreen.getContext('2d');
            offscreenCtx.drawImage(lowerCtx.canvas, 0, 0); // below first
            offscreenCtx.drawImage(upperCtx.canvas, 0, 0); // then merging layer

            lowerCtx.clearRect(0, 0, lowerCtx.canvas.width, lowerCtx.canvas.height);
            lowerCtx.drawImage(offscreen, 0, 0);

            // Remove the upper layer
            newLayers.splice(layerIndex, 1);
            refreshLayerButtonImages(newLayers);
            return newLayers;
        });

        if (activeLayerIndex === layerIndex) {
            setActiveLayerIndex(layerIndex - 1);
        } else if (activeLayerIndex > layerIndex) {
            setActiveLayerIndex(activeLayerIndex - 1);
        }
        setShowLayerMenuIndex(null);
    };


    /** ────────────────────────────── Utility / Local Storage ────────────────────────────── */

    const getLocalStorageKey = useCallback(
        (key) => `${guid}-${key}`,
        [guid]
    );

    const getYesterdayDateInCentralTime = () => {
        const todayInCST = new Date(
            new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' })
        );
        const yesterdayInCST = new Date(todayInCST);
        yesterdayInCST.setDate(todayInCST.getDate() - 1);
        return yesterdayInCST
            .toLocaleString('en-US', { timeZone: 'America/Chicago' })
            .split(',')[0];
    };

    /** 
     * Clears all layers from localStorage, resets the date, and reloads the page. 
     */
    const clearLocalStorageAndReload = () => {
        const isLocal = window.location.hostname === 'localhost';
        if (isLocal) {
            setIsPublished(false);
            const yesterdayCST = getYesterdayDateInCentralTime();
            const lastVisitDateKey = getLocalStorageKey('lastVisitDate');
            localStorage.setItem(lastVisitDateKey, yesterdayCST);
        }

        // Remove ALL layer entries (up to max).
        for (let i = 0; i < MAX_LAYERS; i++) {
            localStorage.removeItem(getLocalStorageKey(`layer${i}`));
        }

        // Clear out all layer canvases
        layers.forEach((layer) => {
            layer.context.clearRect(0, 0, layer.context.canvas.width, layer.context.canvas.height);
        });
        mainContext.current.clearRect(0, 0, mainContext.current.canvas.width, mainContext.current.canvas.height);

        // Reset undo history
        setHistory([]);
        setHistoryIndex(-1);

        // Reset to a single fresh layer (so the layer buttons go back to 1)
        setLayers(() => {
            // Create a new in-memory canvas
            const maxAspectRatio = 1.311;

            let desiredWidth = window.innerWidth * 0.9;
            let desiredHeight = window.innerHeight * 0.7;

            const currentAspectRatio = desiredWidth / desiredHeight;
            if (currentAspectRatio > maxAspectRatio) {
                desiredWidth = desiredHeight * maxAspectRatio;
            }

            const singleCanvas = document.createElement('canvas');
            singleCanvas.width = desiredWidth * 2;
            singleCanvas.height = desiredHeight * 2;
            singleCanvas.style.width = `${desiredWidth}px`;
            singleCanvas.style.height = `${desiredHeight}px`;
            const ctx = singleCanvas.getContext('2d');
            ctx.lineWidth = 2;
            ctx.lineCap = 'round';
            ctx.scale(1, 1);

            return [
                {
                    canvasRef: { current: singleCanvas },
                    context: ctx,
                    iconFile: '/layerIcons/1.png'
                }
            ];
        });

        // Make sure layer index is 0
        setActiveLayerIndex(0);

        // Save the blank state so the first new stroke can be undone
        saveHistory();

        // Refresh layer button images with the new single-layer array
        setTimeout(() => {
            refreshLayerButtonImages(layers);
        }, 0);
    };

    const handleSignatureSubmit = () => {
        if (signature) {
            localStorage.setItem('signature', signature);
        }
        const base64Image = getBase64Image();
        saveDrawing(base64Image, guid, true, dailySubject, signature || localSignature);
        setIsPublished(true);
        setShowSignaturePrompt(false);
        // 1) Fire the Google Ads conversion here
        if (window.gtag) {
            window.gtag('event', 'conversion', { 'send_to': 'AW-968867610/nCnlCJWsv4waEJr-_s0D', 'value': 1.0, 'currency': 'USD' });
        }
    };

    /** ────────────────────────────── Lifecycle / Effects ────────────────────────────── */

    useEffect(() => {
        // Keep track of whether we were previously in landscape.
        // We'll initialize it based on the current window dimensions.
        let wasLandscape = window.innerWidth > window.innerHeight;

        const handleResize = () => {
            // Only proceed if it's a mobile device (Android or iPhone, iPad, iPod):
            const isMobile = /Android|iPhone/i.test(navigator.userAgent);
            if (!isMobile) return;

            // Check if we are in landscape right now:
            const nowIsLandscape = window.innerWidth > window.innerHeight;

            // If we *just rotated* from portrait to landscape, alert immediately:
            if (!wasLandscape && nowIsLandscape) {
                alert('The drawing canvas is optimized for portrait orientation on mobile devices.');
            }

            // Update the previous orientation tracker.
            wasLandscape = nowIsLandscape;
        };

        window.addEventListener('resize', handleResize);

        // Run once on mount so if the user loads in landscape, it alerts immediately:
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Clear out the "recentResize" after a bit
    useEffect(() => {
        if (recentResize) {
            const timer = setTimeout(() => {
                setRecentResize(false);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [recentResize]);

    /**
     * Periodically autosave layers if the canvas has changed.
     */
    useEffect(() => {
        const saveLayersToLocalStorage = () => {
            // 1) Remove any leftover keys for layers we no longer have
            for (let i = layers.length; i < MAX_LAYERS; i++) {
                localStorage.removeItem(getLocalStorageKey(`layer${i}`));
            }

            // 2) Re-save the existing layers
            layers.forEach((layer, index) => {
                const canvas = layer.context.canvas;
                if (canvas) {
                    const dataUrl = canvas.toDataURL();
                    // Store { image, color } as JSON
                    const payload = { image: dataUrl };
                    localStorage.setItem(
                        getLocalStorageKey(`layer${index}`),
                        JSON.stringify(payload)
                    );
                }
            });
        };

        const intervalId = setInterval(() => {
            if (!isCanvasModified) return;
            saveLayersToLocalStorage();
        }, 5000);

        return () => clearInterval(intervalId);
    }, [isPublished, isCanvasModified, layers, getLocalStorageKey]);

    /**
     * Load signature from local storage, show daily subject, etc.
     */
    useEffect(() => {
        setShowSubject(true);
        const savedSignature = localStorage.getItem('signature');
        if (savedSignature) {
            setLocalSignature(savedSignature);
            setSignature(savedSignature);
        }
    }, [setShowSubject]);

    /**
     * Setup shareDrawing function for the "Share" button from parent.
     */
    useEffect(() => {
        const shareDrawing = async () => {
            const ctx = mainContext.current;
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);


            // Fill with white
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

            // Draw each layer on mainCanvas
            layers.forEach((layer) => {
                ctx.drawImage(layer.context.canvas, 0, 0);
            });

            mainCanvasRef.current.toBlob(async (blob) => {
                if (!blob) return;
                const file = new File([blob], 'drawing.png', { type: 'image/png' });
                const shareData = {
                    files: [file],
                    text: "Today's Subject: " + dailySubject
                };

                if (navigator.share) {
                    try {
                        await navigator.share(shareData);
                        console.log('Drawing shared successfully');
                    } catch (error) {
                        console.error('Error sharing the drawing:', error);
                    }
                } else {
                    alert('Web Share API is not supported in your browser.');
                }
            });
        };

        myShareDrawingRef.current = shareDrawing;
        return () => {
            myShareDrawingRef.current = null;
        };
    }, [myShareDrawingRef, dailySubject, layers]);

    /**
     * Setup saveDrawing callback for the "Save" button from parent (which triggers signature prompt).
     */
    useEffect(() => {
        mySaveDrawingRef.current = () => setShowSignaturePrompt(true);
        return () => {
            mySaveDrawingRef.current = null;
        };
    }, [mySaveDrawingRef]);

    /**
     * Prevent default behaviors on mobile devices so we can draw properly.
     */
    useEffect(() => {
        const disableDefaultBehavior = (event) => {
            if (
                event.target.closest('.helpClose') ||
                event.target.closest('.confirm-dialog') ||
                event.target.closest('.splash') ||
                event.target.closest('.palette-z-index') ||
                event.target.closest('.layer-selection') ||
                event.target.closest('.navigation') ||
                event.target.closest('.signature')
            ) {
                return;
            }
            event.preventDefault();
        };

        document.addEventListener('touchstart', disableDefaultBehavior, { passive: false });
        document.addEventListener('touchmove', disableDefaultBehavior, { passive: false });
        document.addEventListener('touchend', disableDefaultBehavior, { passive: false });
        document.addEventListener('mousedown', disableDefaultBehavior, { passive: false });
        document.addEventListener('mousemove', disableDefaultBehavior, { passive: false });
        document.addEventListener('mouseup', disableDefaultBehavior, { passive: false });

        return () => {
            document.removeEventListener('touchstart', disableDefaultBehavior);
            document.removeEventListener('touchmove', disableDefaultBehavior);
            document.removeEventListener('touchend', disableDefaultBehavior);
            document.removeEventListener('mousedown', disableDefaultBehavior);
            document.removeEventListener('mousemove', disableDefaultBehavior);
            document.removeEventListener('mouseup', disableDefaultBehavior);
        };
    }, []);

    /**
     * Ensure our PWA meta tag is set for iOS Safari.
     */
    useEffect(() => {
        const meta = document.createElement('meta');
        meta.name = 'apple-mobile-web-app-capable';
        meta.content = 'yes';
        document.head.appendChild(meta);
        return () => {
            document.head.removeChild(meta);
        };
    }, []);

    /**
     * Initial canvas setup & load from local storage (once per day).
     */

    function countStoredLayers() {
        let layerCount = 0;
        for (let i = 0; i < MAX_LAYERS; i++) {
            const dataUrl = localStorage.getItem(getLocalStorageKey(`layer${i}`));
            if (dataUrl) {
                layerCount++;
            } else {
                // The moment we see there's no layer i, break.
                break;
            }
        }
        return layerCount;
    }

    // 3) The same load function
    function loadLayersFromLocalStorage() {
        const ctx = mainContext.current;
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        const loadPromises = layers.map((layer, index) => {
            return new Promise((resolve) => {
                // Get the JSON string from local storage
                const storedData = localStorage.getItem(getLocalStorageKey(`layer${index}`));
                if (!storedData) {
                    return resolve();
                }

                const parsed = JSON.parse(storedData);
                const image = parsed.image;

                // If we have an image, restore it
                if (image) {
                    const img = new Image();
                    img.onload = () => {
                        layer.context.clearRect(0, 0, layer.context.canvas.width, layer.context.canvas.height);
                        layer.context.drawImage(img, 0, 0);
                        // Also draw onto the main canvas
                        ctx.drawImage(layer.context.canvas, 0, 0);
                        resolve();
                    };
                    img.src = image;
                } else {
                    resolve();
                }
            });
        });

        Promise.all(loadPromises).then(() => {
            refreshLayerButtonImages(layers);
            saveHistory();
        });
    }


    // 2) A separate effect that actually calls the loader once layers is ready:
    useEffect(() => {
        if (!shouldLoad) return;
        if (layers.length === 0) return;          // not ready yet
        if (!mainContext.current) return;         // or still null
        // NOW we have our layers. Let's load them:
        loadLayersFromLocalStorage(mainContext.current);

        setShouldLoad(false);
    }, [shouldLoad, layers]);


    useEffect(() => {
        const getCurrentDateInCentralTime = () => {
            return new Date()
                .toLocaleString('en-US', { timeZone: 'America/Chicago' })
                .split(',')[0];
        };

        const clearLocalStorageDrawings = () => {

            for (let i = 0; i < MAX_LAYERS; i++) {
                localStorage.removeItem(getLocalStorageKey(`layer${i}`));
            }
            layers.forEach((layer) => {
                const ctx = layer.context;
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            });
            mainContext.current.clearRect(
                0,
                0,
                mainContext.current.canvas.width,
                mainContext.current.canvas.height
            );
        };


        // 1) Setup the sizing for all canvases
        const maxAspectRatio = 1.311;

        let desiredWidth = window.innerWidth * 0.9;
        let desiredHeight = window.innerHeight * 0.7;

        const currentAspectRatio = desiredWidth / desiredHeight;
        if (currentAspectRatio > maxAspectRatio) {
            desiredWidth = desiredHeight * maxAspectRatio;
        }

        const initializeCanvas = (canvas) => {
            canvas.width = desiredWidth * 2;
            canvas.height = desiredHeight * 2;
            canvas.style.width = `${desiredWidth}px`;
            canvas.style.height = `${desiredHeight}px`;
        };

        // 2) Initialize mainCanvas
        initializeCanvas(mainCanvasRef.current);
        mainContext.current = mainCanvasRef.current.getContext('2d');
        mainContext.current.scale(1, 1);

        // 3) Initialize workCanvas
        initializeCanvas(workCanvasRef.current);
        workContext.current = workCanvasRef.current.getContext('2d');
        workContext.current.scale(2, 2);

        // 4) Initialize beginDrawLayerCanvas
        initializeCanvas(beginDrawLayerCanvasRef.current);
        beginDrawLayerContext.current = beginDrawLayerCanvasRef.current.getContext('2d');
        beginDrawLayerContext.current.scale(1, 1);

        // 5) offScreenCanvas
        const offScreenCanvas = offScreenCanvasRef.current;
        initializeCanvas(offScreenCanvas);
        offScreenContext.current = offScreenCanvas.getContext('2d');
        offScreenContext.current.scale(1, 1);

        // 6) Initialize layers (we do it once, if not already set)
        setLayers((prev) => {
            // If we already have some layers in state, skip re-initializing
            if (prev.length > 0) return prev;

            const numberOfStoredLayers = countStoredLayers();
            const layerAmount = numberOfStoredLayers || 1; // If none stored, default to 1.

            // By default, start with 1 layer
            const defaultLayers = [];
            for (let i = 0; i < layerAmount; i++) {
                const canvasRef = React.createRef();
                const newCanvas = document.createElement('canvas');
                initializeCanvas(newCanvas);
                const ctx = newCanvas.getContext('2d');
                ctx.lineWidth = 2;
                ctx.lineCap = 'round';
                ctx.scale(1, 1);

                // If this is the root layer and we're not loading from storage, fill it with white.
                if (i === 0 && numberOfStoredLayers === 0) {
                    ctx.fillStyle = 'white';
                    ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);
                }


                defaultLayers.push({
                    canvasRef,
                    context: ctx,
                    iconFile: `/layerIcons/${i + 1}.png`
                });
                // Put the DOM node in the ref
                canvasRef.current = newCanvas;
            }
            return defaultLayers;
        });

        // 7) Check if it's a new day or the same day
        const currentDateInCentralTime = getCurrentDateInCentralTime();
        const lastVisitDateKey = getLocalStorageKey('lastVisitDate');
        const lastVisitDate = localStorage.getItem(lastVisitDateKey);

        if (!lastVisitDate || lastVisitDate !== currentDateInCentralTime) {
            // New day: clear local storage & set date
            clearLocalStorageDrawings();
            setIsPublished(false);
            localStorage.setItem(lastVisitDateKey, currentDateInCentralTime);
            setShowSplash(true);
        } else {
            // Same day: after layers are in state, we load from local storage
            // We'll do so in a timeout to ensure setLayers has completed
            //loadLayersFromLocalStorage(mainContext);
            setShouldLoad(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guid, getLocalStorageKey, setIsPublished]);

    // We remove the previous effect that re-loaded from storage every time `layers` changed.
    // That way, we ONLY load once on mount (if it's the same day).

    /** ────────────────────────────── Drawing Logic ────────────────────────────── */

    const drawMainCanvas = useCallback(() => {

        const ctx = mainContext.current;

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        layers.forEach((layer, i) => {
            //alert('drawing main canvas layer...' + i);
            // If this is the active layer, composite the "work" / "beginDraw" changes on top
            // alert('active main canvas...' + activeLayerIndex);
            if (i === activeLayerIndex) {
                layer.context.clearRect(0, 0, layer.context.canvas.width, layer.context.canvas.height);

                // Start from the layer’s existing drawing
                layer.context.drawImage(beginDrawLayerContext.current.canvas, 0, 0);

                // Apply brush changes
                layer.context.globalAlpha = alpha;
                if (eraseMode) {
                    layer.context.globalCompositeOperation = 'destination-out';
                }
                layer.context.drawImage(workCanvasRef.current, 0, 0);
                layer.context.globalAlpha = 1;
                layer.context.globalCompositeOperation = 'source-over';
            }
            ctx.drawImage(layer.context.canvas, 0, 0);
        });
    }, [alpha, eraseMode, layers, activeLayerIndex]);


    useEffect(() => {
        if (!mainContext.current || layers.length === 0) return;
        // redraw the main canvas from the existing layers.
        const ctx = mainContext.current;
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        layers.forEach((layer, i) => {
            ctx.drawImage(layer.context.canvas, 0, 0);
        });

    }, [layers]);


    const getOffset = (event) => {
        const rect = mainCanvasRef.current.getBoundingClientRect();
        let clientX, clientY;

        if (event.touches && event.touches.length > 0) {
            clientX = event.touches[0].clientX;
            clientY = event.touches[0].clientY;
        } else {
            clientX = event.clientX;
            clientY = event.clientY;
        }

        const offsetX = (clientX - rect.left) / scale;
        const offsetY = (clientY - rect.top) / scale;
        return { offsetX, offsetY };
    };

    const selectColorAtPosition = (event) => {
        const rect = mainCanvasRef.current.getBoundingClientRect();
        const touch = event.touches[0];

        const offsetX =
            (touch.clientX - rect.left) * (mainCanvasRef.current.width / rect.width);
        const offsetY =
            (touch.clientY - rect.top) * (mainCanvasRef.current.height / rect.height);

        const ctx = mainContext.current;
        const imageData = ctx.getImageData(offsetX, offsetY, 1, 1).data;
        const [r, g, b, a] = imageData;
        const rgbaColor = `rgba(${r},${g},${b},1)`;
        setColor(rgbaColor);
    };


    const handleTransformStart = (event) => {
        if (!layers[activeLayerIndex]) return;

        setIsTransforming(true);

        // Copy the current layer to 'beginDraw'
        beginDrawLayerContext.current.clearRect(
            0,
            0,
            beginDrawLayerContext.current.canvas.width,
            beginDrawLayerContext.current.canvas.height
        );
        beginDrawLayerContext.current.drawImage(
            layers[activeLayerIndex].context.canvas,
            0,
            0
        );

        const rect = mainCanvasRef.current.getBoundingClientRect();

        if (event.touches && event.touches.length === 1) {
            // Single-finger => translation only
            const { offsetX, offsetY } = getOffset(event);

            setTransformStartData({
                startTouches: [], // not used for single-finger
                anchorX: 0,
                anchorY: 0,
                startDistance: 0,
                startAngle: 0,
                // We'll store these to measure movement
                startOffsetX: offsetX,
                startOffsetY: offsetY
            });
            setTransformPreview({
                translateX: 0,
                translateY: 0,
                rotation: 0,
                scale: 1,
            });
        } else if (event.touches && event.touches.length === 2) {
            // Two-finger => track pinch / rotate / move
            const t1 = event.touches[0];
            const t2 = event.touches[1];
            // positions in client coords:
            const x1 = t1.clientX - rect.left;
            const y1 = t1.clientY - rect.top;
            const x2 = t2.clientX - rect.left;
            const y2 = t2.clientY - rect.top;

            const midX = (x1 + x2) / 2;
            const midY = (y1 + y2) / 2;
            const dx = x2 - x1;
            const dy = y2 - y1;
            const startDistance = Math.sqrt(dx * dx + dy * dy);
            const startAngle = Math.atan2(dy, dx);

            setTransformStartData({
                startTouches: [
                    { x: x1, y: y1 },
                    { x: x2, y: y2 }
                ],
                anchorX: midX,
                anchorY: midY,
                startDistance,
                startAngle,
                startOffsetX: 0,
                startOffsetY: 0,
            });
            setTransformPreview({
                translateX: 0,
                translateY: 0,
                rotation: 0,
                scale: 1,
            });
        }
    };

    /**
     * 2) Transform-mode move:
     *   - If one finger => translation
     *   - If two fingers => pinch + rotate + translation around the midpoint
     *   - Re-draw the layer context with the updated transform in real time.
     */
    const handleTransformMove = (event) => {
        if (!isTransforming || !layers[activeLayerIndex]) return;
        event.preventDefault();

        const layer = layers[activeLayerIndex];
        const { anchorX, anchorY, startDistance, startAngle, startOffsetX, startOffsetY } = transformStartData;

        if (event.touches && event.touches.length === 1) {
            // Single-finger => track translation
            const { offsetX, offsetY } = getOffset(event);

            const deltaX = offsetX - startOffsetX;
            const deltaY = offsetY - startOffsetY;

            // Update the preview
            const newPreview = {
                translateX: deltaX,
                translateY: deltaY,
                rotation: 0,
                scale: 1,
            };
            setTransformPreview(newPreview);

            layer.context.setTransform(1, 0, 0, 1, 0, 0);
            // Re-draw
            layer.context.clearRect(0, 0, layer.context.canvas.width, layer.context.canvas.height);


            layer.context.translate(deltaX, deltaY);
            layer.context.drawImage(beginDrawLayerContext.current.canvas, 0, 0);


            drawMainCanvas();
        }
        else if (event.touches && event.touches.length === 2) {
            // Two-finger => pinch + rotate around anchor
            const rect = mainCanvasRef.current.getBoundingClientRect();
            const t1 = event.touches[0];
            const t2 = event.touches[1];
            const x1 = (t1.clientX - rect.left) / scale;
            const y1 = (t1.clientY - rect.top) / scale;
            const x2 = (t2.clientX - rect.left) / scale;
            const y2 = (t2.clientY - rect.top) / scale;

            // New midpoint (for overall translation)
            const midX = (x1 + x2) / 2;
            const midY = (y1 + y2) / 2;
            const dx = x2 - x1;
            const dy = y2 - y1;
            const newDistance = Math.sqrt(dx * dx + dy * dy);
            const newAngle = Math.atan2(dy, dx);

            const scaleFactor = newDistance / startDistance;
            const rotationDelta = newAngle - startAngle;

            // Also translate by movement of the anchor
            const anchorDeltaX = midX - anchorX;
            const anchorDeltaY = midY - anchorY;

            layer.context.setTransform(1, 0, 0, 1, 0, 0);
            // Re-draw
            layer.context.clearRect(0, 0, layer.context.canvas.width, layer.context.canvas.height);

            // Step 1: move to the new midpoint
            layer.context.translate(midX, midY);
            // Step 2: rotate
            layer.context.rotate(rotationDelta);
            // Step 3: scale
            layer.context.scale(scaleFactor, scaleFactor);
            // Step 4: move origin so the anchor lines up

            // Step 4: move origin so the anchor lines up
            layer.context.translate(-anchorX, -anchorY);
            layer.context.drawImage(beginDrawLayerContext.current.canvas, 0, 0);
            drawMainCanvas();
        }
    };

    /**
     * 3) Transform-mode end:
     *   - The user lifts finger(s). We now have the “final” transform already drawn on the layer context.
     *   - Just finalize, record a history snapshot, etc.
     */
    const handleTransformEnd = (event) => {
        if (!isTransforming) return;
        setIsTransforming(false);

        const layer = layers[activeLayerIndex];
        const ctx = layer.context;

        drawMainCanvas();

        ctx.setTransform(1, 0, 0, 1, 0, 0); // Reset transform matrix

        // Clear the transform preview state
        setTransformPreview({
            translateX: 0,
            translateY: 0,
            rotation: 0,
            scale: 1,
        });

        // 3) *** CRUCIAL FIX ***: 
        // Copy the updated layer pixels back into beginDrawLayerContext
        beginDrawLayerContext.current.clearRect(
            0, 0,
            beginDrawLayerContext.current.canvas.width,
            beginDrawLayerContext.current.canvas.height
        );
        beginDrawLayerContext.current.drawImage(layer.context.canvas, 0, 0);


        setIsCanvasModified(true);
        saveHistory();
        refreshLayerButtonImages(layers);
    };
    // --- END ADDED / MODIFIED CODE ---

    const startDrawing = (event) => {
        const { offsetX, offsetY } = getOffset(event);

        if (sampleMode) {
            selectColorAtPosition(event);
            setSampleMode(false);
        } else {

            // --- ADDED / MODIFIED CODE BELOW ---
            // If we are in transform mode, do NOT draw. Instead, begin transform.
            if (transformMode) {
                handleTransformStart(event);
                return;
            }
            // --- END ADDED / MODIFIED CODE ---


            // Copy the current layer to 'beginDraw'
            beginDrawLayerContext.current.clearRect(
                0,
                0,
                beginDrawLayerContext.current.canvas.width,
                beginDrawLayerContext.current.canvas.height
            );
            beginDrawLayerContext.current.drawImage(
                layers[activeLayerIndex].context.canvas,
                0,
                0
            );

            if (event.touches && event.touches.length === 2) {
                setTouchPoints([
                    { x: event.touches[0].clientX, y: event.touches[0].clientY },
                    { x: event.touches[1].clientX, y: event.touches[1].clientY }
                ]);
                return;
            }

            event.preventDefault();

            workContext.current.clearRect(
                0,
                0,
                workContext.current.canvas.width,
                workContext.current.canvas.height
            );
            workContext.current.beginPath();

            if (
                lineMode === 'square' ||
                lineMode === 'circle' ||
                lineMode === 'straight' ||
                lineMode === 'horizontal'
            ) {
                setLineStart({ x: offsetX, y: offsetY });
            } else {
                workContext.current.moveTo(offsetX, offsetY);
            }

            workContext.current.strokeStyle = color;
            workContext.current.lineWidth = brushSize;
            setLastPoint({ offsetX, offsetY });
            setIsDrawing(true);
            setIsCanvasModified(true);
        }
    };

    const draw = (event) => {
        // If transformMode is active, we skip normal drawing logic
        if (transformMode) {
            handleTransformMove(event);
            return;
        }
        if (!isDrawing) return;
        if (event.touches && event.touches.length === 2) return;
        event.preventDefault();
        if (!isDrawing) return;

        const { offsetX, offsetY } = getOffset(event);
        const { offsetX: lastX, offsetY: lastY } = lastPoint;
        const midPointX = (lastX + offsetX) / 2;
        const midPointY = (lastY + offsetY) / 2;
        setHasMoved(true);


        if (lineMode === 'square') {
            const { x: startX, y: startY } = lineStart;
            workContext.current.clearRect(0, 0, workContext.current.canvas.width, workContext.current.canvas.height);
            workContext.current.strokeRect(startX, startY, offsetX - startX, offsetY - startY);
        } else if (lineMode === 'circle') {
            const { x: startX, y: startY } = lineStart;
            const radiusX = Math.abs(offsetX - startX) / 2;
            const radiusY = Math.abs(offsetY - startY) / 2;
            const centerX = startX + (offsetX - startX) / 2;
            const centerY = startY + (offsetY - startY) / 2;
            workContext.current.clearRect(0, 0, workContext.current.canvas.width, workContext.current.canvas.height);
            workContext.current.beginPath();
            workContext.current.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);
            workContext.current.stroke();
        } else if (lineMode === 'straight') {
            const { x: startX, y: startY } = lineStart;
            workContext.current.clearRect(0, 0, workContext.current.canvas.width, workContext.current.canvas.height);
            workContext.current.beginPath();
            workContext.current.moveTo(startX, startY);
            workContext.current.lineTo(offsetX, offsetY);
            workContext.current.stroke();
        } else if (lineMode === 'horizontal') {
            const { x: startX, y: startY } = lineStart;
            const deltaX = Math.abs(offsetX - startX);
            const deltaY = Math.abs(offsetY - startY);
            workContext.current.clearRect(0, 0, workContext.current.canvas.width, workContext.current.canvas.height);
            workContext.current.beginPath();
            if (deltaX > deltaY) {
                workContext.current.moveTo(startX, startY);
                workContext.current.lineTo(offsetX, startY);
            } else {
                workContext.current.moveTo(startX, startY);
                workContext.current.lineTo(startX, offsetY);
            }
            workContext.current.stroke();
        } else {
            // squiggle
            workContext.current.quadraticCurveTo(lastX, lastY, midPointX, midPointY);
            workContext.current.stroke();
        }

        setLastPoint({ offsetX, offsetY });
        drawMainCanvas();
    };

    const endDrawing = (event) => {

        // --- ADDED / MODIFIED CODE BELOW ---
        // If transformMode is active, finalize the transform rather than draw
        if (transformMode) {
            handleTransformEnd(event);
            return;
        }
        event.preventDefault();
        if (!isDrawing) return;

        const { offsetX, offsetY } = lastPoint;
        if (lineMode === 'squiggle' && !hasMoved && !recentResize) {
            // Single dot
            workContext.current.clearRect(0, 0, workContext.current.canvas.width, workContext.current.canvas.height);
            workContext.current.beginPath();
            workContext.current.arc(offsetX, offsetY, brushSize / 2, 0, 2 * Math.PI);
            workContext.current.fillStyle = color;
            workContext.current.fill();
        }

        workContext.current.closePath();
        setIsDrawing(false);
        setHasMoved(false);
        drawMainCanvas();

        // Immediately clear the workCanvas so it won't re-apply old strokes
        workContext.current.clearRect(
            0, 0,
            workCanvasRef.current.width,
            workCanvasRef.current.height
        );

        saveHistory();
        refreshLayerButtonImages(layers); // << Add this line
    };


    const handleCombinedTouchMove = (event) => {
        if (event.touches.length === 2 && !transformMode) {
            handleGestureChange(event);
        } else {
            draw(event);
        }
    };

    const handleGestureChange = (e) => {
        clearTimeout(touchHoldTimerRef.current);
        e.preventDefault();
        if (e.touches.length > 1) {
            const newTouches = [
                { x: e.touches[0].clientX, y: e.touches[0].clientY },
                { x: e.touches[1].clientX, y: e.touches[1].clientY }
            ];
            const initialMidpoint = {
                x: (touchPoints[0].x + touchPoints[1].x) / 2,
                y: (touchPoints[0].y + touchPoints[1].y) / 2
            };
            const newMidpoint = {
                x: (newTouches[0].x + newTouches[1].x) / 2,
                y: (newTouches[0].y + newTouches[1].y) / 2
            };
            const initialDistance = Math.sqrt(
                Math.pow(touchPoints[0].x - touchPoints[1].x, 2) +
                Math.pow(touchPoints[0].y - touchPoints[1].y, 2)
            );
            const newDistance = Math.sqrt(
                Math.pow(newTouches[0].x - newTouches[1].x, 2) +
                Math.pow(newTouches[0].y - newTouches[1].y, 2)
            );
            const scaleChange = newDistance / initialDistance;
            setScale(scale * scaleChange);
            setRecentResize(true);

            const positionChange = {
                x: newMidpoint.x - initialMidpoint.x,
                y: newMidpoint.y - initialMidpoint.y
            };
            setPosition({
                x: position.x + positionChange.x,
                y: position.y + positionChange.y
            });
            setTouchPoints(newTouches);
        }
    };

    /** ────────────────────────────── Helper / Utils ────────────────────────────── */

    function getBase64Image() {
        const canvas = mainCanvasRef.current;
        if (!canvas) return null;

        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;
        const tempCtx = tempCanvas.getContext('2d');

        tempCtx.fillStyle = 'white';
        tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
        tempCtx.drawImage(canvas, 0, 0);

        return tempCanvas.toDataURL('image/jpeg');
    }

    const saveDrawing = async (
        base64Image,
        userId,
        published,
        drawingSubject,
        signature
    ) => {
        try {
            await fetch('https://ibt18xjvyj.execute-api.us-east-1.amazonaws.com', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    image: base64Image,
                    userId: userId,
                    published: published,
                    drawingSubject: drawingSubject,
                    signature: signature
                })
            });
        } catch (error) {
            console.error('Failed to save drawing:', error);
        }
    };

    const handleBrushSizeChange = (size) => {
        const sizeMap = { XS: 2, S: 5, M: 10, L: 20, XL: 40 };
        setBrushSize(sizeMap[size]);
    };

    const handleAlphaChange = (newAlpha) => {
        setAlpha(newAlpha);
    };

    const handleLineModeChange = (lineMode) => {
        setLineMode(lineMode);
    };


    const handleTransformModeChange = (transformMode) => {
        setTransformMode(transformMode);
    };

    const handleEraseModeChange = (eraseMode) => {
        setEraseMode(eraseMode);
    };

    const handleClearCanvas = () => {
        setShowConfirm(true);
    };

    const handleSpecialBrushModeChange = (specialBrushMode) => {
        setSpecialBrushMode(specialBrushMode)
    }

    const handleConfirm = () => {
        clearLocalStorageAndReload();
        //set the initial undo state.
        setShowConfirm(false);
    };

    const handleCancel = () => {
        setShowConfirm(false);
    };

    const undoLastAction = useCallback(() => {
        if (historyIndex <= 0) return;
        const newHistoryIndex = historyIndex - 1;
        setHistoryIndex(newHistoryIndex);

        const previousCanvases = history[newHistoryIndex];
        const offScreenCtx = offScreenContext.current;
        offScreenCtx.clearRect(0, 0, offScreenCtx.canvas.width, offScreenCtx.canvas.height);

        const imagePromises = previousCanvases.map((dataUrl, index) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => {
                    resolve({ img, index });
                };
                img.src = dataUrl;
            });
        });

        Promise.all(imagePromises).then((images) => {
            images.sort((a, b) => a.index - b.index);

            images.forEach(({ img, index }) => {
                const layerCtx = layers[index].context;
                layerCtx.clearRect(0, 0, layerCtx.canvas.width, layerCtx.canvas.height);

                // If layer 0 is the background
                if (index === 0) {
                    layerCtx.fillStyle = 'white';
                    layerCtx.fillRect(0, 0, layerCtx.canvas.width, layerCtx.canvas.height);
                }
                layerCtx.drawImage(img, 0, 0);
                offScreenCtx.drawImage(img, 0, 0);
            });



            const mainCtx = mainContext.current;
            mainCtx.clearRect(0, 0, mainCtx.canvas.width, mainCtx.canvas.height);
            mainCtx.drawImage(offScreenCtx.canvas, 0, 0);

            refreshLayerButtonImages(layers);
        });
    }, [history, historyIndex, layers]);

    const saveHistory = useCallback(() => {
        // Save each layer as a dataUrl
        const canvases = layers.map((layer) => layer.context.canvas.toDataURL());
        setHistory((prevHistory) => {
            const newHistory = [...prevHistory.slice(0, historyIndex + 1), canvases];
            if (newHistory.length > 20) newHistory.shift();
            return newHistory;
        });
        setHistoryIndex((prevIndex) => Math.min(prevIndex + 1, 19));
    }, [layers, historyIndex]);

    /** ────────────────────────────── Layer Management ────────────────────────────── */

    const addLayer = () => {
        if (layers.length >= MAX_LAYERS) {
            alert(`You can only have up to ${MAX_LAYERS} layers.`);
            return;
        }
        const index = layers.length;
        const canvasRef = React.createRef();
        // Create a new in-memory canvas
        const newCanvas = document.createElement('canvas');
        // match sizing to mainCanvas
        newCanvas.width = mainCanvasRef.current.width;
        newCanvas.height = mainCanvasRef.current.height;
        newCanvas.style.width = mainCanvasRef.current.style.width;
        newCanvas.style.height = mainCanvasRef.current.style.height;

        const ctx = newCanvas.getContext('2d');
        ctx.lineWidth = 2;
        ctx.lineCap = 'round';
        ctx.scale(1, 1);

        canvasRef.current = newCanvas;

        setLayers((prev) => [
            ...prev,
            {
                canvasRef,
                context: ctx,
                // Assign a random color for the new layer

            }
        ]);

        // Force-blank the brand new canvas
        ctx.clearRect(0, 0, newCanvas.width, newCanvas.height);

        // Remove old localStorage key if any
        localStorage.removeItem(getLocalStorageKey(`layer${index}`));

        // Make the newly added layer active
        setActiveLayerIndex(index);
    };

    const deleteLayer = (layerIndex) => {
        if (layers.length <= 1) {
            alert('You must have at least one layer.');
            return;
        }
        //alert('Deleting layerIndex =' + layerIndex + ' old activeLayerIndex =' + activeLayerIndex);

        // If deleting the active layer, choose a new active layer
        let newActiveLayerIndex = activeLayerIndex;
        if (layerIndex === activeLayerIndex) {
            newActiveLayerIndex = 0;
        } else if (layerIndex < activeLayerIndex) {
            newActiveLayerIndex = activeLayerIndex - 1;
        }



        setLayers((prev) => {
            const newLayers = [...prev];
            newLayers.splice(layerIndex, 1);
            return newLayers;
        });

        setIsCanvasModified(true);

        setActiveLayerIndex(newActiveLayerIndex);
        setShowLayerMenuIndex(null);
    };

    /** ────────────────────────────── Rendering ────────────────────────────── */

    const canvasStyle = {
        border: '2px solid grey',
        margin: '10px',
        touchAction: 'none',
        backgroundColor: 'white',
        transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`
    };

    const hiddenCanvasStyle = {
        transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
        display: 'none'
    };

    const handleSplashOkClick = () => {
        setShowSplash(false);
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <ColorPalette
                color={color}
                setColor={setColor}
                onBrushSizeChange={handleBrushSizeChange}
                onAlphaChange={handleAlphaChange}
                onUndo={undoLastAction}
                onLineModeChange={handleLineModeChange}
                onEraseModeChange={handleEraseModeChange}
                sampleMode={sampleMode}
                setSampleMode={setSampleMode}
                onClearCanvas={handleClearCanvas}
                onSpecialBrushMode={handleSpecialBrushModeChange}
                onTransformModeChange={handleTransformModeChange}
            />

            <div className="container" style={{ position: 'relative' }}>
                {/* MAIN CANVAS for final composited drawing */}
                <canvas
                    ref={mainCanvasRef}
                    style={canvasStyle}
                    onMouseDown={startDrawing}
                    onTouchMove={handleCombinedTouchMove}
                    onMouseMove={draw}
                    onMouseUp={endDrawing}
                    onMouseLeave={endDrawing}
                    onTouchStart={startDrawing}
                    onTouchEnd={endDrawing}
                    onTouchCancel={endDrawing}
                />

                {/* Hidden helper canvases */}
                <canvas ref={workCanvasRef} style={hiddenCanvasStyle} />
                <canvas ref={beginDrawLayerCanvasRef} style={hiddenCanvasStyle} />
                <canvas ref={offScreenCanvasRef} style={hiddenCanvasStyle} />

                {/* Each layer's hidden canvas */}
                {layers.map((layer, index) => (
                    <canvas
                        key={index}
                        ref={layer.canvasRef}
                        style={hiddenCanvasStyle}
                    />
                ))}
            </div>

            <div
                className="layer-selection"
                style={{
                    position: 'fixed',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end', // Make sure they stay right-justified
                    margin: '0px',
                    zIndex: 1
                }}
            >
                {[...layers].reverse().map((layer, reversedIndex) => {
                    const realIndex = layers.length - 1 - reversedIndex;
                    return (
                        <div
                            key={realIndex}
                            style={{
                                position: 'relative',     // so we can absolutely position the menu
                                marginBottom: '6px',
                                width: '40px',            // lock width so the layout doesn’t shift
                                textAlign: 'right'
                            }}
                        >
                            {/* The layer button itself */}
                            <button
                                style={{
                                    // Use the layer's color as the button background
                                    // For selected layer, you can add a thicker border or other highlight
                                    border: activeLayerIndex === realIndex ? '1px solid cyan' : '1px solid grey',
                                    backgroundColor: 'white',
                                    backgroundImage: `url(${layerButtonImages[realIndex] || ''})`,
                                    // Ensure the image covers the button
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: '35px',
                                    height: '35px',   // give it a square shape
                                    boxSizing: 'border-box',
                                    padding: '0px'
                                }}
                                onClick={() => {

                                    if (activeLayerIndex === realIndex) {
                                        // If already active, toggle the menu
                                        setShowLayerMenuIndex((prev) => (prev === realIndex ? null : realIndex));
                                    } else {
                                        // Otherwise, select this layer and close any open menu
                                        setActiveLayerIndex(realIndex);
                                        setShowLayerMenuIndex(null);
                                    }
                                }}
                            >

                            </button>

                            {/* Conditionally render the horizontal menu to the left (absolute) */}
                            {
                                showLayerMenuIndex === realIndex && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            right: 'calc(100% + 4px)',   // place the menu to the left of the button
                                            top: 0,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            backgroundColor: 'white',
                                            border: '1px solid black',
                                            gap: '2px',
                                            padding: '2px'
                                        }}
                                    >
                                        <button
                                            onClick={() => moveLayerUp(realIndex)}
                                            disabled={realIndex === layers.length - 1}
                                        >
                                            ↑
                                        </button>
                                        <button
                                            onClick={() => moveLayerDown(realIndex)}
                                            disabled={realIndex === 0}
                                        >
                                            ↓
                                        </button>
                                        <button
                                            onClick={() => mergeLayerDown(realIndex)}
                                            disabled={realIndex === 0}
                                        >
                                            M↓
                                        </button>
                                        <button
                                            onClick={() => mergeLayerUp(realIndex)}
                                            disabled={realIndex === layers.length - 1}
                                        >
                                            M↑
                                        </button>
                                        <button onClick={() => deleteLayer(realIndex)}>X</button>
                                    </div>
                                )
                            }
                        </div>
                    );
                })}

                {/* Add new layer button remains right-justified and unaffected */}
                <button
                    style={{
                        fontSize: '14px',
                        backgroundColor: 'white',
                        color: 'black',
                        border: '1px solid black',
                        marginTop: '0px',
                        width: '35px',
                        height: '35px',
                        padding: '0px'
                    }}
                    onClick={() => {
                        // Close any open layer menu
                        setShowLayerMenuIndex(null);

                        // Then add the layer
                        addLayer();
                    }}
                >+</button>
            </div>
            {
                showConfirm && (
                    <ConfirmDialog
                        message="Are you sure you want to reset the canvas?"
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                    />
                )
            }

            {
                showSplash && (
                    <div
                        className="splash"
                        style={{
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            width: '80%',
                            maxWidth: '500px',
                            border: '2px solid grey',
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1000,
                            transform: 'translate(-50%, -50%)',
                            padding: '20px',
                            boxSizing: 'border-box',
                        }}
                    >
                        <b>Welcome to The Daily Doodle!</b>
                        <p>
                            Every day, everyone gets the same subject and the same simple drawing
                            tools—leveling the playing field for pure creativity! See how others
                            interpret today’s subject and share your unique take.
                        </p>
                        Tap to start drawing! 🎨
                        <button
                            onClick={handleSplashOkClick}
                            style={{
                                fontSize: '18px', // Adjusted font size
                                padding: '10px 20px', // Adjusted padding for better appearance
                                cursor: 'pointer', // Add pointer cursor for better UX
                                marginTop: '10px', // Add margin for spacing
                            }}
                        >
                            OK
                        </button>
                    </div>
                )
            }

            {
                showSignaturePrompt && (
                    <div
                        className="signature"
                        style={{
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'white',
                            padding: '20px',
                            border: '1px solid black',
                            zIndex: 1001
                        }}
                    >
                        <p>Sign your work or remain anonymous.</p>
                        <input
                            type="text"
                            value={signature}
                            onChange={(e) => setSignature(e.target.value)}
                            placeholder="Enter your signature"
                            style={{
                                fontSize: '16px',
                                width: '100%',
                                padding: '1px',
                                marginBottom: '10px'
                            }}
                        />
                        <div>
                            <button onClick={handleSignatureSubmit}>Publish</button>
                        </div>
                    </div>
                )
            }
        </div >
    );
};

export default DrawingCanvas;
